const sidebarScrollAreaSelector = "#side-nav-scroll-area"
const sessionStorageKey = "sideNavScrollOffset"

export const useSyncSidebarScroll = () => {
  const saveScrollOffset = React.useCallback(() => {
    const nav = document.querySelector(sidebarScrollAreaSelector)
    if (nav) {
      const scrollTop = nav.scrollTop ?? 0
      sessionStorage.setItem(sessionStorageKey, scrollTop)
    }
  }, [])

  const updateScroll = React.useCallback(() => {
    const nav = document.querySelector(sidebarScrollAreaSelector)
    if (nav) {
      const scrollTop = sessionStorage.getItem(sessionStorageKey)
      if (scrollTop && !isNaN(scrollTop)) {
        nav.scrollTop = parseInt(scrollTop, 10)
      }
    }
  }, [])

  return { saveScrollOffset, updateScroll }
}
