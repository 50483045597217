import { useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"
import Dropdown from "#Root/ui/Dropdown"

import ChartColorPalette from "./ChartColorPalette"
import ChartLineThickness from "./ChartLineThickness"
import ChartMarkers from "./ChartMarkers"
import ChartSupportLines from "./ChartSupportLines"

const ChartSettingsMenu = () => {
  const {
    settings,
    setHorizontalSupportLines,
    setVerticalSupportLines,
    setColorPalette,
    setLineThickness,
    setShowMarkers,
  } = useChartSettingsContext()

  const closeAllOtherMenus = () => {
    // These dropdowns are toggled by JQuery, and don't belong in React's tree
    // Let's keep them in sync so that we won't have them open at the same time
    const helpDropdown = document.querySelector("#help-dropdown")
    const profileDropdown = document.querySelector("#profile-dropdown")

    if (helpDropdown && !helpDropdown.classList.contains("hidden")) {
      helpDropdown.classList.add("hidden")
    }

    if (profileDropdown && !profileDropdown.classList.contains("hidden")) {
      profileDropdown.classList.add("hidden")
    }
  }

  return (
    <Dropdown>
      <Dropdown.PlainButton onClick={closeAllOtherMenus} className="group">
        <span className="group-[.active]:text-white">Charts</span>
      </Dropdown.PlainButton>
      <Dropdown.Items align="left" className="max-h-none px-0 py-0 [240px] -left-4 mt-2">
        <ChartColorPalette colorPalette={settings.colorPalette} setColorPalette={setColorPalette} />
        <ChartMarkers showMarkers={settings.showMarkers} setShowMarkers={setShowMarkers} />
        <ChartSupportLines
          horizontalSupportLines={settings.horizontalSupportLines}
          verticalSupportLines={settings.verticalSupportLines}
          setHorizontalSupportLines={setHorizontalSupportLines}
          setVerticalSupportLines={setVerticalSupportLines}
        />
        <ChartLineThickness
          lineThickness={settings.lineThickness}
          setLineThickness={setLineThickness}
        />
      </Dropdown.Items>
    </Dropdown>
  )
}

export default ChartSettingsMenu
