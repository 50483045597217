import { interpolateTurbo, schemeObservable10, schemeSpectral } from "d3"

import { useChartSettingsMutation } from "./useChartSettingsMutation"

export const DEFAULT_COLOR_PALETTE = "main"

const mainPalette = [
  "hsla(227, 78%, 48%, 1)",
  "hsla(15, 75%, 63%, 1)",
  "hsla(76, 84%, 33%, 1)",
  "hsla(340, 72%, 72%, 1)",
  "hsla(221, 22%, 51%, 1)",
  "hsla(180, 67%, 27%, 1)",
  "hsla(36, 84%, 53%, 1)",
  "hsla(216, 51%, 59%, 1)",
  "hsla(156, 41%, 54%, 1)",
  "hsla(350, 71%, 26%, 1)",
]

const pairedPalette = [
  "#1f78b4",
  "#33a02c",
  "#e31a1c",
  "#ff7f00",
  "#6a3d9a",
  "#b15928",
  "#a6cee3",
  "#b2df8a",
  "#fb9a99",
  "#fdbf6f",
  "#cab2d6",
  "#ffff99",
]

export const colorPaletteMap = {
  main: { name: "main", type: "steps", values: mainPalette },
  stepped1: { name: "stepped1", type: "steps", values: pairedPalette },
  stepped2: { name: "stepped2", type: "steps", values: schemeObservable10 },
  continuous1: { name: "continuous1", type: "continuous", fn: interpolateTurbo },
  divergent1: { name: "divergent1", type: "divergent", values: schemeSpectral },
}

export const DEFAULT_CHART_SETTINGS = {
  horizontalSupportLines: false,
  verticalSupportLines: false,
  showMarkers: true,
  colorPalette: DEFAULT_COLOR_PALETTE,
  lineThickness: 1,
}

const LINE_THICKNESS_OPTIONS = [1, 1.5, 2]

export const ChartSettingsContext = React.createContext()

const parseDefaultProps = (props) => ({
  horizontalSupportLines: props.horizontalSupportLines === true,
  verticalSupportLines: props.verticalSupportLines === true,
  colorPalette: Object.keys(colorPaletteMap).includes(props.colorPalette)
    ? props.colorPalette
    : DEFAULT_COLOR_PALETTE,
  showMarkers: props.showMarkers === true,
  lineThickness: LINE_THICKNESS_OPTIONS.includes(parseFloat(props.lineThickness))
    ? parseFloat(props.lineThickness)
    : DEFAULT_CHART_SETTINGS.lineThickness,
})

export const ChartSettingsProvider = ({ value, children }) => {
  const [settings, setSettings] = React.useState(() => {
    return value ? parseDefaultProps(value) : DEFAULT_CHART_SETTINGS
  })

  const {
    persistHorizontalSupportLines,
    persistVerticalSupportLines,
    persistMarkers,
    persistLineThickness,
    persistColorPalette,
  } = useChartSettingsMutation()

  const setHorizontalSupportLines = React.useCallback(
    (isEnabled) => {
      // Update optimistically
      setSettings((prev) => ({ ...prev, horizontalSupportLines: isEnabled }))
      // Persist
      persistHorizontalSupportLines(isEnabled)
    },
    [persistHorizontalSupportLines],
  )

  const setVerticalSupportLines = React.useCallback(
    (isEnabled) => {
      // Update optimistically
      setSettings((prev) => ({ ...prev, verticalSupportLines: isEnabled }))
      // Persist
      persistVerticalSupportLines(isEnabled)
    },
    [persistVerticalSupportLines],
  )

  const setColorPalette = React.useCallback(
    (colorPaletteName) => {
      let nextColorPalette = colorPaletteName

      if (!colorPaletteMap[colorPaletteName]) {
        console.warn(`Invalid colorPalette value: ${colorPaletteName}`)
        nextColorPalette = DEFAULT_COLOR_PALETTE
      }

      // Update optimistically
      setSettings((prevSettings) => ({ ...prevSettings, colorPalette: nextColorPalette }))
      // Persist
      persistColorPalette(nextColorPalette)
    },
    [persistColorPalette],
  )

  const setShowMarkers = React.useCallback(
    (showMarkers) => {
      // Update optimistically
      setSettings((prevSettings) => ({ ...prevSettings, showMarkers }))
      // Persist
      persistMarkers(showMarkers)
    },
    [persistMarkers],
  )

  const setLineThickness = React.useCallback(
    (lineThickness) => {
      // Update optimistically
      setSettings((prevSettings) => ({ ...prevSettings, lineThickness }))
      // Persist
      persistLineThickness(lineThickness)
    },
    [persistLineThickness],
  )

  return (
    <ChartSettingsContext.Provider
      value={{
        settings,
        setHorizontalSupportLines,
        setVerticalSupportLines,
        setShowMarkers,
        setColorPalette,
        setLineThickness,
      }}
    >
      {children}
    </ChartSettingsContext.Provider>
  )
}

ChartSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    horizontalSupportLines: PropTypes.bool,
    verticalSupportLines: PropTypes.bool,
    showMarkers: PropTypes.bool,
    colorPalette: PropTypes.string,
    lineThickness: PropTypes.number,
  }),
}

export const useChartSettingsContext = () => {
  const context = React.useContext(ChartSettingsContext)

  return context
}
