import gql from "graphql-tag"

export default gql`
  fragment Viewer on Viewer {
    id
    usedFeatures
    hideGettingStarted
    isExternallyControlled
  }
`
