import Button from "#Root/ui/Button/Button"
import CopyTextButton from "#Root/ui/CopyTextButton/CopyTextButton"

const exportFormats = ["png", "json", "csv"]

export function ClipGraphMenu({ visual, onClose }) {
  const [title, setTitle] = React.useState(visual.title)
  const [renderLegend, setRenderLegend] = React.useState(true)
  const [exportFormat, setExportFormat] = React.useState("png")

  const getUrl = () => {
    const baseParams = `?title=${encodeURIComponent(title)}&renderLegend=${renderLegend}`
    switch (exportFormat) {
      case "png":
        return `${visual.pngUrl}${baseParams}`
      case "json":
        return visual.jsonUrl
      case "csv":
        return visual.csvUrl
    }
  }

  const url = getUrl()

  return (
    <>
      <div className="px-5 py-4 border-b border-gray-200">
        <h2 className="font-semibold">Export graph</h2>
      </div>
      <div className="px-5 py-4">
        {exportFormat === "png" && (
          <>
            <div className="c-form">
              <label className="c-form__label ignore-old-css" htmlFor="title">
                Graph title
              </label>
              <div className="c-form__element">
                <label className="c-textfield ignore-old-css">
                  <input
                    type="text"
                    className="c-textfield__input"
                    id="title"
                    placeholder="Graph title"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div className="c-form">
              <label className="c-form__label ignore-old-css" htmlFor="legend">
                Legend
              </label>
              <div className="c-form__element">
                <div className="c-checkbox ignore-old-css">
                  <input
                    className="c-checkbox__input focus:ring-0"
                    type="checkbox"
                    id="legend"
                    defaultChecked={renderLegend}
                    onClick={() => setRenderLegend(!renderLegend)}
                  />
                  <label
                    className="c-checkbox__label cursor-pointer ignore-old-css"
                    htmlFor="legend"
                  >
                    Show legend
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="c-form__label ignore-old-css">Format</div>
        <div className="c-radio mb-6">
          {exportFormats.map((format) => (
            <React.Fragment key={format}>
              <input
                className="c-radio__input focus:ring-0"
                type="radio"
                id={format}
                name="export_format"
                value={format}
                checked={exportFormat === format}
                onChange={(e) => setExportFormat(e.target.value)}
              />
              <label className="c-radio__label ignore-old-css" htmlFor={format}>
                {format.toUpperCase()}
              </label>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="px-5 py-3 border-t border-gray-200 gap-3 flex">
        <a
          href={`${url}?download`}
          className="c-button c-button--xs "
          target="_blank"
          rel="noopener noreferrer"
          role="button"
        >
          Download
        </a>
        <CopyTextButton
          value={url}
          text="Copy URL"
          className="c-button c-button--white c-button--xs"
        />
        <Button onClick={onClose} color="white" className="ml-auto">
          Cancel
        </Button>
      </div>
    </>
  )
}

ClipGraphMenu.propTypes = {
  visual: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ClipGraphMenu
