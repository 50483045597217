// exposing an object with the features, so that we can rely on autocomplete,
// and to avoid using strings that could be misspelled or changed in the future.
export const platformFeatures = {
  HOST_MONITORING: "host_monitoring",
  PERFORMANCE_ISSUE_LIST: "performance_issue_list",
  PERFORMANCE_ACTIONS: "performance_actions",
  PERFORMANCE_TRACES: "performance_traces",
  PERFORMANCE_SLOW_EVENTS: "performance_slow_events",
  PERFORMANCE_SLOW_API_REQUESTS: "performance_slow_api_requests",
  PERFORMANCE_SLOW_QUERIES: "performance_slow_queries",
}

const hideForPlatforms = {
  logplex: [platformFeatures.PERFORMANCE_TRACES],
  logplexv1: [platformFeatures.PERFORMANCE_TRACES],
  logplexv2: [platformFeatures.PERFORMANCE_TRACES],
  heroku: [platformFeatures.PERFORMANCE_TRACES],
  dokku: [platformFeatures.PERFORMANCE_TRACES],
  vercel: [platformFeatures.PERFORMANCE_TRACES],
  opentelemetry: [
    platformFeatures.HOST_MONITORING,
    platformFeatures.PERFORMANCE_ISSUE_LIST,
    platformFeatures.PERFORMANCE_ACTIONS,
    platformFeatures.PERFORMANCE_SLOW_EVENTS,
    platformFeatures.PERFORMANCE_SLOW_API_REQUESTS,
    platformFeatures.PERFORMANCE_SLOW_QUERIES,
  ],
  samples: [platformFeatures.PERFORMANCE_TRACES],
  frontend: [platformFeatures.PERFORMANCE_TRACES],
}

export const PlatformContext = React.createContext({ platforms: [] })

const usePlatformFeature = (featureList = hideForPlatforms) => {
  const platforms = React.useContext(PlatformContext)

  const hasPlatform = (platform) => platforms.includes(platform)

  const isFeatureVisible = (feature) => {
    if (platforms.length === 0) {
      // If there are no platforms, we show everything except the open-telemetry
      // We explicitly handle the open-telemetry case because of this edge case
      // https://appsignal.slack.com/archives/C01HXL10GC8/p1731665421095619
      return feature !== platformFeatures.PERFORMANCE_TRACES
    }

    const featuresToBeHidden = []

    platforms.forEach((platform) => {
      if (Object.keys(featureList).includes(platform)) {
        featuresToBeHidden.push(...featureList[platform])
      }
    })

    const filteredFeatureList = featuresToBeHidden.filter(
      (potentialFeature) => potentialFeature === feature,
    )
    return filteredFeatureList.length !== platforms.length
  }

  return { isFeatureVisible, hasPlatform }
}

export default usePlatformFeature
