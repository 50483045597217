import { lazy } from "react"

const ActionShow = lazy(() => import("../actions/show"))
const ActionsPage = lazy(() => import("../ActionsPage/ActionsPage"))
const AddDashboardPage = lazy(() => import("../AddDashboardPage/AddDashboardPage"))
const AnomalyIncidentsPage = lazy(() => import("../AnomalyIncidentsPage/AnomalyIncidentsPage"))
const AnomalyIncidentPage = lazy(() => import("../AnomalyIncidentPage/AnomalyIncidentPage"))
const AnomalyIncidentAlertsPage = lazy(
  () => import("../AnomalyIncidentAlertsPage/AnomalyIncidentAlertsPage"),
)
const AnomalyIncidentAlertPage = lazy(
  () => import("../AnomalyIncidentAlertPage/AnomalyIncidentAlertPage"),
)
const AnomalyIncidentLogbookPage = lazy(
  () => import("../AnomalyIncidentLogbookPage/AnomalyIncidentLogbookPage"),
)
const AnomalyIncidentGraphsPage = lazy(() => import("../AnomalyIncidentGraphsPage"))
const DashboardShow = lazy(() => import("../dashboard/show"))
const EventGroupShow = lazy(() => import("../event_group/show"))
const EventShow = lazy(() => import("../event/show"))
const EventPage = lazy(() => import("../EventPage/EventPage"))
const EventsIndex = lazy(() => import("../events/index"))
const ExceptionIncidentsGraphs = lazy(() => import("../exception_incidents/graphs"))
const ExceptionIncidentsPage = lazy(
  () => import("../ExceptionIncidentsPage/ExceptionIncidentsPage"),
)
const ExceptionIncidentPage = lazy(() => import("../ExceptionIncidentPage/ExceptionIncidentPage"))
const GettingStartedPage = lazy(() => import("../GettingStartedPage/GettingStartedPage"))
const HostMetricsPage = lazy(() => import("../HostMetricsPage/HostMetricsPage"))
const HostMetricsInspectPage = lazy(() => import("../HostMetricsPage/HostMetricsInspectPage"))
const HostUsagePage = lazy(() => import("../HostUsagePage/HostUsagePage"))
const HostUsageInspectPage = lazy(() => import("../HostUsagePage/HostUsageInspectPage"))
const MarkersIndex = lazy(() => import("../markers/index"))
const MagicDashboardBlankSlate = lazy(() => import("../../ui/BlankSlate/MagicDashboardBlankSlate"))
const MetricsIndex = lazy(() => import("../metrics/index"))
const NewSiteHeroku = lazy(() => import("../sites/NewSiteHeroku"))
const NewSiteJs = lazy(() => import("../sites/NewSiteJs"))
const OrganizationsPage = lazy(() => import("../OrganizationsPage/OrganizationsPage"))
const PerformanceIncidentsGraphs = lazy(() => import("../performance_incidents/graphs"))
const PerformanceIncidentsPage = lazy(
  () => import("../PerformanceIncidentsPage/PerformanceIncidentsPage"),
)
const PerformanceIncidentPage = lazy(
  () => import("../PerformanceIncidentPage/PerformanceIncidentPage"),
)
const PerformanceIncidentSamplesPage = lazy(
  () => import("../PerformanceIncidentSamplesPage/PerformanceIncidentSamplesPage"),
)
const PerformanceIncidentGraphsPage = lazy(
  () => import("../PerformanceIncidentGraphsPage/PerformanceIncidentGraphsPage"),
)
const PerformanceIncidentLogbookPage = lazy(
  () => import("../PerformanceIncidentLogbookPage/PerformanceIncidentLogbookPage"),
)
const PerformanceIncidentSamplePage = lazy(
  () => import("../PerformanceIncidentSamplePage/PerformanceIncidentSamplePage"),
)
const PerformanceTracingActionsPage = lazy(
  () => import("../PerformanceTracingActionsPage/PerformanceTracingActionsPage"),
)
const PerformanceTracingActionPage = lazy(
  () => import("../PerformanceTracingActionPage/PerformanceTracingActionPage"),
)
const PerformanceTracingTracesPage = lazy(
  () => import("../PerformanceTracingTracesPage/PerformanceTracingTracesPage"),
)
const PerformanceTracingTracePage = lazy(
  () => import("../PerformanceTracingTracePage/PerformanceTracingTracePage"),
)
const PerformanceTracingGraphsPage = lazy(
  () => import("../PerformanceTracingGraphsPage/PerformanceTracingGraphsPage"),
)

const SearchIndex = lazy(() => import("../search/index"))
const SiteNew = lazy(() => import("../sites/NewSite"))
const TriggersIndex = lazy(() => import("../triggers/index"))
const UptimeMonitorPage = lazy(() => import("../UptimeMonitorPage/UptimeMonitorPage"))
const AssignmentsPage = lazy(() => import("../AssignmentsPage/AssignmentsPage"))
const StatusPagesPage = lazy(() => import("../StatusPagesPage/StatusPagesPage"))
const OrganizationPage = lazy(() => import("../OrganizationPage/OrganizationPage"))
const LogsPage = lazy(() => import("../LogsPage/LogsPage"))
const LogsTriggersPage = lazy(() => import("../LogsTriggersPage/LogsTriggersPage"))
const LogsViewPage = lazy(() => import("../LogsPage/LogsViewPage"))
const SourcesPage = lazy(() => import("../SourcesPage/SourcesPage"))
const SourcePage = lazy(() => import("../SourcePage/SourcePage"))
const ExceptionIncidentSamplesPage = lazy(
  () => import("../ExceptionIncidentSamplesPage/ExceptionIncidentSamplesPage"),
)
const ExceptionIncidentTracesPage = lazy(
  () => import("../ExceptionIncidentTracesPage/ExceptionIncidentTracesPage"),
)
const ExceptionIncidentTracePage = lazy(
  () => import("../ExceptionIncidentTracePage/ExceptionIncidentTracePage"),
)
const ExceptionIncidentGraphsPage = lazy(() => import("../ExceptionIncidentGraphsPage"))
const ExceptionIncidentAttributesPage = lazy(
  () => import("../ExceptionIncidentAttributesPage/ExceptionIncidentAttributesPage"),
)
const ExceptionIncidentLogbookPage = lazy(
  () => import("../ExceptionIncidentLogbookPage/ExceptionIncidentLogbookPage"),
)
const ExceptionIncidentSamplePage = lazy(
  () => import("../ExceptionIncidentSamplePage/ExceptionIncidentSamplePage"),
)
const LogsIncidentsPage = lazy(() => import("../LogsIncidentsPage/LogsIncidentsPage"))
const LogsIncidentPage = lazy(() => import("../LogsIncidentPage/LogsIncidentPage"))
const LogsIncidentLogbookPage = lazy(
  () => import("../LogsIncidentLogbookPage/LogsIncidentLogbookPage"),
)
const LogsIncidentGraphsPage = lazy(() => import("../LogsIncidentGraphsPage"))
const LogsIncidentLinesPage = lazy(() => import("../LogsIncidentLinesPage/LogsIncidentLinesPage"))
const CheckInsPage = lazy(() => import("../CheckInsPage/CheckInsPage"))
const CheckInPage = lazy(() => import("../CheckInPage/CheckInPage"))
const CheckInLogbookPage = lazy(() => import("../CheckInLogbookPage/CheckInLogbookPage"))
const CheckInOccurrencesPage = lazy(
  () => import("../CheckInOccurrencesPage/CheckInOccurrencesPage"),
)
const CheckInOccurrencePage = lazy(() => import("../CheckInOccurrencePage/CheckInOccurrencePage"))
const CheckInInstallationPage = lazy(
  () => import("../CheckInInstallationPage/CheckInInstallationPage"),
)
const CsvExportsPage = lazy(() => import("../CsvExports/CsvExportsPage"))

const ComponentMap = {
  ActionShow,
  ActionsPage,
  AddDashboardPage,
  AnomalyIncidentsPage,
  AnomalyIncidentPage,
  AnomalyIncidentAlertsPage,
  AnomalyIncidentAlertPage,
  AnomalyIncidentLogbookPage,
  AnomalyIncidentGraphsPage,
  DashboardShow,
  EventGroupShow,
  EventShow,
  EventPage,
  EventsIndex,
  ExceptionIncidentsGraphs,
  ExceptionIncidentsPage,
  ExceptionIncidentPage,
  GettingStartedPage,
  HostMetricsPage,
  HostMetricsInspectPage,
  HostUsagePage,
  HostUsageInspectPage,
  MarkersIndex,
  MagicDashboardBlankSlate,
  MetricsIndex,
  NewSiteHeroku,
  OrganizationsPage,
  NewSiteJs,
  PerformanceIncidentsGraphs,
  PerformanceIncidentsPage,
  PerformanceIncidentPage,
  PerformanceIncidentSamplesPage,
  PerformanceIncidentGraphsPage,
  PerformanceIncidentLogbookPage,
  PerformanceIncidentSamplePage,
  PerformanceTracingActionsPage,
  PerformanceTracingActionPage,
  PerformanceTracingTracesPage,
  PerformanceTracingTracePage,
  PerformanceTracingGraphsPage,
  SearchIndex,
  SiteNew,
  TriggersIndex,
  UptimeMonitorPage,
  AssignmentsPage,
  StatusPagesPage,
  OrganizationPage,
  LogsPage,
  LogsTriggersPage,
  LogsViewPage,
  SourcesPage,
  SourcePage,
  ExceptionIncidentSamplesPage,
  ExceptionIncidentTracesPage,
  ExceptionIncidentTracePage,
  ExceptionIncidentGraphsPage,
  ExceptionIncidentAttributesPage,
  ExceptionIncidentLogbookPage,
  ExceptionIncidentSamplePage,
  LogsIncidentsPage,
  LogsIncidentPage,
  LogsIncidentLogbookPage,
  LogsIncidentGraphsPage,
  LogsIncidentLinesPage,
  CheckInsPage,
  CheckInPage,
  CheckInLogbookPage,
  CheckInOccurrencesPage,
  CheckInOccurrencePage,
  CheckInInstallationPage,
  CsvExportsPage,
}

export default ComponentMap
