import * as Dialog from "@radix-ui/react-dialog"
import { flushSync } from "react-dom"

import cn from "#Root/utils/cn"

const Overlay = ({ open = false, onClose, size = "xl", children }) => {
  if (typeof onClose !== "function") onClose = () => {}
  const [isOpen, setIsOpen] = React.useState(open)

  const sizeMap = {
    sm: "c-overlay__inner--sm",
    md: "c-overlay__inner--md",
    lg: "c-overlay__inner--lg",
    xl: "c-overlay__inner--xl",
    "2xl": "c-overlay__inner--2xl",
    full: "c-overlay__inner--full",
    fullscreen: "c-overlay__inner--full p-4",
  }

  React.useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={async (newIsOpen) => {
        /*
          Ensure the state is persisted (and the modal focusTrap is cleaned up)
          before we call the `onClose` callback to close the modal in the URL
        */
        flushSync(() => {
          setIsOpen(newIsOpen)
        })
        if (newIsOpen === false) {
          onClose()
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="c-overlay" />
        <Dialog.Content
          className={`c-overlay__inner z-[1000] ${sizeMap[size]}`}
          aria-describedby={undefined}
        >
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

Overlay.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl", "2xl", "full"]),
  children: PropTypes.node.isRequired,
}

const OverlayTitle = ({ title }) => {
  return (
    <div className="c-overlay__header">
      <div className="c-overlay__title">
        <Dialog.DialogTitle asChild>
          <h1>{title}</h1>
        </Dialog.DialogTitle>
      </div>
      <Dialog.Close className="ignore-old-css c-overlay__close">
        <i className="align-middle fa fa-times"></i>
      </Dialog.Close>
    </div>
  )
}

OverlayTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

const OverlayContent = ({ children, background = "white", noSpacing = false, className }) => {
  const backgroundMap = {
    white: "bg-white",
    gray: "bg-gray-100",
  }

  return (
    <div className={cn("c-overlay__content", className)}>
      <div className={`c-box mb-0 ${backgroundMap[background]}`}>
        <div className={`${noSpacing ? "p-0" : "p-5"}`}>{children}</div>
      </div>
    </div>
  )
}

OverlayContent.propTypes = {
  children: PropTypes.node,
  background: PropTypes.oneOf(["white", "gray"]),
  noSpacing: PropTypes.bool,
  className: PropTypes.string,
}

const OverlayActions = ({ children }) => {
  return (
    <footer className="c-box__footer -mx-5 -mb-5 mt-5 flex items-center gap-2">{children}</footer>
  )
}

OverlayActions.propTypes = {
  children: PropTypes.node,
}

const OverlayHint = ({ children }) => {
  return <div className="c-overlay__hint">{children}</div>
}

OverlayHint.propTypes = {
  children: PropTypes.node,
}

Overlay.Title = OverlayTitle
Overlay.Content = OverlayContent
Overlay.Actions = OverlayActions
Overlay.Hint = OverlayHint

export default Overlay
