import { useMutation, useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import LinkToParams from "#Root/components/shared/LinkToParams"
import UrlHelper from "#Root/components/shared/url_helper"
import AppInfoQuery from "#Root/graphql/app_info_query"
import CreateDashboardMutation from "#Root/graphql/create_dashboard_mutation"
import CustomMetricsDashboardDefinitionQuery from "#Root/graphql/custom_metrics_dashboard_definition_query"
import UpdateDashboardMutation from "#Root/graphql/update_dashboard_mutation"
import { useRouter } from "#Root/hooks"
import Button from "#Root/ui/Button"
import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"
import Overlay from "#Root/ui/Overlay"

export const DashboardFormOverlay = ({ params, open }) => {
  const { appId, action } = params
  let { id: dashboardId } = params
  const editDashboard = action === "edit"
  const { router, navigateWithParams } = useRouter()
  const [createDashboard] = useMutation(CreateDashboardMutation)
  const [updateDashboard] = useMutation(UpdateDashboardMutation)
  const { data, loading } = useQuery(CustomMetricsDashboardDefinitionQuery, {
    skip: !dashboardId,
    variables: {
      appId,
      id: dashboardId,
    },
  })
  const [isLoading, setIsLoading] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const titleRef = useRef()
  const descriptionRef = useRef()

  useEffect(() => {
    if (!editDashboard) {
      return
    }

    if (!loading && data) {
      if (titleRef && titleRef.current) {
        titleRef.current.value = data.app.dashboard.title
      }
      if (descriptionRef && descriptionRef.current) {
        descriptionRef.current.value = data.app.dashboard.description
      }
      setButtonDisabled(false)
    }
  }, [editDashboard, loading, data])

  const handleSubmit = async (event) => {
    event?.preventDefault()

    if (isLoading) {
      return
    }
    if (buttonDisabled) {
      return
    }

    setIsLoading(true)

    if (editDashboard) {
      await updateDashboard({
        awaitRefetchQueries: true,
        variables: {
          appId,
          id: dashboardId,
          title: titleRef.current.value,
          description: descriptionRef.current.value,
        },
        refetchQueries: [
          {
            query: AppInfoQuery,
            variables: { appId },
          },
        ],
      })
    } else {
      const data = await createDashboard({
        awaitRefetchQueries: true,
        variables: {
          appId,
          title: titleRef.current.value,
          description: descriptionRef.current.value,
        },
        refetchQueries: [
          {
            query: AppInfoQuery,
            variables: { appId },
          },
        ],
      })
      dashboardId = data.data.createDashboard.id
    }

    resetInputFields()
    setIsLoading(false)

    router.navigate(UrlHelper.metricsShowPath({ id: dashboardId }), { trigger: true })
  }

  const resetInputFields = () => {
    if (titleRef && titleRef.current) {
      titleRef.current.value = ""
    }
    if (descriptionRef && descriptionRef.current) {
      descriptionRef.current.value = ""
    }
  }

  const handleClose = (event) => {
    if (event) event.preventDefault()
    navigateWithParams({ overlay: null, action: null })
  }

  const renderContent = () => {
    if (editDashboard && loading) {
      return (
        <div className="bg-white rounded">
          <LoadingAnimation message="Loading your dashboard data" />
        </div>
      )
    } else {
      return (
        <form onSubmit={handleSubmit} className="bg-white rounded">
          <div className="border-b border-gray-200 -mx-5 -mt-5 mb-5 p-5 flex items-center">
            <h1 className="c-heading mb-0">Dashboard settings</h1>
            {!editDashboard && (
              <LinkToParams className="ml-auto" params={{ overlay: "dashboardImportOverlay" }}>
                Import dashboard
              </LinkToParams>
            )}
          </div>
          <div className="c-form">
            <label className="c-form__label">Title</label>
            <div className="c-form__element">
              <div className="c-textfield">
                <input
                  type="text"
                  className="c-textfield__input"
                  placeholder="Title"
                  onChange={() => setButtonDisabled(titleRef.current.value.trim() === "")}
                  ref={titleRef}
                />
              </div>
            </div>
            <p className="c-form__description">
              Use a <code className="c-inline-code">/</code> to create (one) sub-group of
              dashboards. <br /> For example:{" "}
              <code className="c-inline-code">MongoDB/Query Load</code>.
            </p>
          </div>

          <div className="c-form mb-0">
            <label className="c-form__label" htmlFor="">
              Description
            </label>
            <span className="c-form__hint">optional</span>
            <div className="c-form__element">
              <div className="c-textarea">
                <textarea
                  type="text"
                  className="c-textarea__input"
                  placeholder="Description"
                  rows="4"
                  ref={descriptionRef}
                />
              </div>
            </div>
          </div>
          <Overlay.Actions>
            <Button disabled={buttonDisabled} onClick={handleSubmit} type="submit">
              {editDashboard ? "Update dashboard" : "Create dashboard"}
            </Button>
            <Button color="white" onClick={handleClose}>
              Cancel
            </Button>
          </Overlay.Actions>
        </form>
      )
    }
  }

  if (!open) return null

  return (
    <Overlay open={open} onClose={handleClose} size="sm">
      <Overlay.Title
        title={editDashboard ? "Edit dashboard" : "New dashboard"}
        handleClose={handleClose}
      />
      <Overlay.Content>{renderContent()}</Overlay.Content>
    </Overlay>
  )
}

DashboardFormOverlay.propTypes = {
  open: PropTypes.bool,
  params: PropTypes.object.isRequired,
}

export default DashboardFormOverlay
