import { useContext } from "react"

import { EnabledLabFeaturesContext } from "../contexts/EnabledLabFeaturesContext"

export const useLabFeature = () => {
  const enabledLabFeatures = useContext(EnabledLabFeaturesContext)

  const isFeatureEnabled = (feature) => {
    return enabledLabFeatures.includes(feature)
  }

  return { isFeatureEnabled }
}

export default useLabFeature
