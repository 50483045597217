window.isIntercomLoaded = function () {
  return typeof Intercom == "function"
}

window.loadIntercom = function () {
  if ($("body").data("use-intercom") !== true) {
    // Exit early when in test environment
    return
  }

  if (window.isIntercomLoaded()) {
    return
  }

  window.loadIntercomScript()
}

// Based on Intercom's script, but updated the load logic as the script
// itself wasn't compatible with being loaded after the DOM was loaded.
window.loadIntercomScript = function () {
  /* eslint-disable semi */
  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", window.intercomSettings);
    } else {
      const d = document;
      const i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src =
        "https://widget.intercom.io/widget/" + window.intercomSettings.app_id;
      const x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    }
  })();
  /* eslint-enable semi */
}
