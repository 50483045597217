import PropTypes from "prop-types"
import React, { useEffect, useLayoutEffect } from "react"

import { TIME_DETECTIVE_SHORTCUT_KEY } from "#Root/constants/KeyboardShortcuts"
import usePlatformFeature, { platformFeatures } from "#Root/hooks/usePlatformFeature"
import { useRouter } from "#Root/hooks/useRouter"
import { useSyncSidebarScroll } from "#Root/hooks/useSyncSidebarScroll"
import { currentRange } from "#Root/utils/TimeDetective"

import Ident from "../../utils/Ident"
import withAppInfo from "../../wrappers/app_info"
import withParamNavigator from "../../wrappers/param_navigator"
import withUsedFeature from "../../wrappers/used_features"
import UrlHelper from "../shared/url_helper"
import { renderDashboardMenuItems } from "./DashboardMenuItems"
import Link from "./link.jsx"
import { renderLogViewMenuItems } from "./LogViewMenuItems"
import SideNavigation from "./side_navigation"
import TrialNotification from "./TrialNotification"

export const Navigation = ({ params, appInfo, hasUsedFeature }) => {
  const viewer = appInfo?.viewer
  const refetch = appInfo?.refetch
  const { start, end } = currentRange()
  const { currentPathWithParams } = useRouter()
  const { isFeatureVisible } = usePlatformFeature()
  const { updateScroll } = useSyncSidebarScroll()

  useLayoutEffect(() => {
    updateScroll()
  }, [])

  useEffect(() => {
    const toggleHideGettingStarted = () => {
      if (refetch != undefined) {
        refetch()
      }
    }

    document.addEventListener("hide_getting_started", toggleHideGettingStarted)
    return () => {
      document.removeEventListener("hide_getting_started", toggleHideGettingStarted)
    }
  }, [refetch])

  const trackAddDashboardClick = (_event) => {
    Ident.trackAction("NewDashboardNavigationClick")
  }

  const AddDashboardButton = () => {
    return (
      <li>
        <Link
          className="c-side-nav__subitem c-button c-button--white c-button--sm inline-flex mt-2"
          href={UrlHelper.addDashboardPagePath()}
          onClick={trackAddDashboardClick}
        >
          <span className="hover:no-underline">Add dashboard</span>
        </Link>
      </li>
    )
  }

  return (
    <div
      id="side-nav-scroll-area"
      className="flex flex-col h-full px-3 overflow-x-hidden items-baseline"
    >
      <ul className="c-side-nav my-4 flex-grow pb-24 w-full">
        {/* Getting started */}
        {viewer?.hideGettingStarted === false && (
          <SideNavigation
            id="nav-app-getting-started"
            title="Getting started"
            color="blue"
            icon="fa-lightbulb-on"
            type="link"
            href={UrlHelper.gettingStartedPagePath()}
          />
        )}

        {/* Dashboards */}
        <SideNavigation
          id="nav-app-dashboards"
          title="Dashboards"
          color="purple"
          icon="fa-tachometer-alt"
          type="menu"
          href={UrlHelper.dashboardShowPath()}
        >
          <SideNavigation
            href={UrlHelper.dashboardShowPath()}
            title="Overview"
            type="subitem"
            fullMatch
          />
          {renderDashboardMenuItems(appInfo, hasUsedFeature)}
          <AddDashboardButton />
        </SideNavigation>

        {/* Deploys */}
        <SideNavigation
          id="nav-app-deploys"
          title="Deploys"
          color="yellow"
          icon="fa-rocket"
          type="link"
          href={UrlHelper.markersIndexPath()}
        />

        {/* Errors */}
        <SideNavigation id="nav-app-errors" title="Errors" color="orange" icon="fa-bug" type="menu">
          <SideNavigation
            href={UrlHelper.exceptionIncidentsIndexPath()}
            title="Issue list"
            type="subitem"
          >
            <SideNavigation href={UrlHelper.exceptionIncidentPagePath()} type="dummy" />
            <SideNavigation
              href={UrlHelper.exceptionIncidentAttributesPagePath()}
              type="dummy"
              fullMatch
            />
            <SideNavigation
              href={UrlHelper.exceptionIncidentLogbookPagePath()}
              type="dummy"
              fullMatch
            />
            <SideNavigation
              href={UrlHelper.exceptionIncidentGraphsPagePath()}
              type="dummy"
              fullMatch
            />
            <SideNavigation
              href={UrlHelper.exceptionIncidentSamplesPagePath()}
              type="dummy"
              fullMatch
            />
            <SideNavigation
              href={UrlHelper.exceptionIncidentTracesPagePath()}
              type="dummy"
              fullMatch
            />
            <SideNavigation
              href={UrlHelper.exceptionIncidentTracePagePath()}
              type="dummy"
              fullMatch
            />
          </SideNavigation>
          <SideNavigation
            href={UrlHelper.exceptionIncidentsGraphsPath()}
            title="Graphs"
            type="subitem"
            fullMatch
          />
        </SideNavigation>

        {/* Performance */}
        <SideNavigation
          id="nav-app-performance"
          title="Performance"
          color="green"
          icon="fa-rabbit-fast"
          type="menu"
        >
          {isFeatureVisible(platformFeatures.PERFORMANCE_ISSUE_LIST) ? (
            <SideNavigation
              href={UrlHelper.performanceIncidentsIndexPath()}
              title="Issue list"
              type="subitem"
            >
              <SideNavigation href={UrlHelper.performanceIncidentPagePath()} type="dummy" />
              <SideNavigation
                href={UrlHelper.performanceIncidentLogbookPagePath()}
                type="dummy"
                fullMatch
              />
              <SideNavigation
                href={UrlHelper.performanceIncidentGraphsPagePath()}
                type="dummy"
                fullMatch
              />
              <SideNavigation
                href={UrlHelper.performanceIncidentSamplesPagePath()}
                type="dummy"
                fullMatch
              />
            </SideNavigation>
          ) : null}
          <SideNavigation
            href={UrlHelper.performanceIncidentsGraphsPath()}
            title="Graphs"
            type="subitem"
            fullMatch
          />
          {isFeatureVisible(platformFeatures.PERFORMANCE_ACTIONS) ? (
            <SideNavigation href={UrlHelper.actionsPagePath()} title="Actions" type="subitem" />
          ) : null}
          {isFeatureVisible(platformFeatures.PERFORMANCE_TRACES) ? (
            <SideNavigation
              href={UrlHelper.performanceTracingActionsPagePath()}
              title="Traces"
              type="subitem"
              fullMatch
            >
              <SideNavigation
                href={UrlHelper.performanceTracingActionPagePath()}
                type="dummy"
                fullMatch
              />
              <SideNavigation
                href={UrlHelper.performanceTracingTracesPagePath()}
                type="dummy"
                fullMatch
              />
              <SideNavigation
                href={UrlHelper.performanceTracingTracePagePath()}
                type="dummy"
                fullMatch
              />
              <SideNavigation
                href={UrlHelper.performanceTracingGraphsPagePath()}
                type="dummy"
                fullMatch
              />
            </SideNavigation>
          ) : null}

          {isFeatureVisible(platformFeatures.PERFORMANCE_SLOW_EVENTS) ? (
            <SideNavigation href={UrlHelper.eventsIndexPath()} title="Slow events" type="subitem" />
          ) : null}

          {isFeatureVisible(platformFeatures.PERFORMANCE_SLOW_API_REQUESTS) ? (
            <SideNavigation
              href={UrlHelper.slowEventsPagePath({ group: "requests" })}
              title="Slow API requests"
              type="subitem"
            />
          ) : null}

          {isFeatureVisible(platformFeatures.PERFORMANCE_SLOW_QUERIES) ? (
            <SideNavigation
              href={UrlHelper.slowEventsPagePath({ group: "queries" })}
              title="Slow queries"
              type="subitem"
            />
          ) : null}
        </SideNavigation>
        {/* Anomaly detection */}
        <SideNavigation
          id="nav-app-anomaly-detection"
          title="Anomaly detection"
          color="red"
          icon="fa-siren-on"
          type="menu"
        >
          <SideNavigation
            href={UrlHelper.anomalyIncidentsIndexPath()}
            title="Issue list"
            type="subitem"
          />
          <SideNavigation href={UrlHelper.triggersIndexPath()} title="Triggers" type="subitem" />
        </SideNavigation>

        {/* Check-ins */}
        <SideNavigation
          id="nav-app-check-ins"
          title="Check-ins"
          color="blue"
          icon="fa-check-square"
          type="link"
          href={UrlHelper.checkInsIndexPath()}
        />
        {/* Uptime monitoring */}
        <SideNavigation
          id="nav-app-uptime-monitoring"
          title="Uptime monitoring"
          color="yellow"
          icon="fa-traffic-light"
          type="link"
          href={UrlHelper.uptimeMonitorsPagePath()}
        />

        {/* Host monitoring */}
        {isFeatureVisible(platformFeatures.HOST_MONITORING) ? (
          <SideNavigation
            id="nav-app-host-monitoring"
            title="Host monitoring"
            color="teal"
            icon="fa-server"
            type="menu"
          >
            <SideNavigation
              href={UrlHelper.hostMetricsIndexPath()}
              title="Host metrics"
              type="subitem"
            />
            <SideNavigation
              href={UrlHelper.hostUsageIndexPath()}
              title="Host usage"
              type="subitem"
            />
          </SideNavigation>
        ) : null}

        {/* Logs */}
        <SideNavigation
          id="nav-app-logs"
          title="Logging"
          color="pink"
          icon="fas fa-line-columns"
          type="menu"
          href={UrlHelper.logsPagePath()}
        >
          <SideNavigation
            href={UrlHelper.logsIncidentsPagePath()}
            title="Issue list"
            type="subitem"
            fullMatch
          >
            <SideNavigation href={UrlHelper.logsIncidentPagePath()} type="dummy" />
            <SideNavigation href={UrlHelper.logsIncidentLogbookPagePath()} type="dummy" fullMatch />
            <SideNavigation href={UrlHelper.logsIncidentLinesPagePath()} type="dummy" fullMatch />
            <SideNavigation href={UrlHelper.logsIncidentGraphsPagePath()} type="dummy" fullMatch />
          </SideNavigation>
          <SideNavigation
            href={UrlHelper.logsTriggersPagePath()}
            title="Triggers"
            type="subitem"
            fullMatch
          />
          <SideNavigation
            href={UrlHelper.logsPagePath()}
            title="All logs"
            type="subitem"
            fullMatch
          />
          <SideNavigation title="Views" type="submenu">
            {renderLogViewMenuItems(appInfo)}
          </SideNavigation>
          <SideNavigation
            href={UrlHelper.sourcesPagePath()}
            title="Manage sources"
            type="subitem"
          />
        </SideNavigation>
        <SideNavigation
          id="nav-app-td"
          title="Time Detective"
          keyboardShortcut={TIME_DETECTIVE_SHORTCUT_KEY}
          color="gray"
          icon="fa-user-secret"
          type="link"
          href={currentPathWithParams({
            overlay: "timeDetective",
            td_start: start.toISOString(),
            td_end: end.toISOString(),
          })}
        />
      </ul>

      <ul className="c-side-nav absolute w-full bottom-0 -mx-3 p-3 space-y-1 bg-white border-t">
        <li className="c-side-nav__section">
          {params?.organizationSlug && (
            <TrialNotification organizationSlug={params.organizationSlug} />
          )}
        </li>
        <li className="c-side-nav__section">
          <a className="c-side-nav__item" href={UrlHelper.editSitePath()}>
            <i className="c-side-nav__item-icon fa fa-cog fa-fw min-w-[17px]" />
            <span className="c-side-nav__item-title responsive-hidden">App settings</span>
          </a>
        </li>
      </ul>
    </div>
  )
}

Navigation.propTypes = {
  appInfo: PropTypes.object,
  hasUsedFeature: PropTypes.func,
  currentPathWithParams: PropTypes.func,
  params: PropTypes.object,
}

export default withParamNavigator(withAppInfo(withUsedFeature(Navigation)))
