import React from "react"
import { ApolloProvider } from "react-apollo"
import { createRoot } from "react-dom/client"

import Router from "#Root/utils/router"

import App from "../components/app"
import { apolloClient } from "../utils/apollo-client"

$(document).ready(function () {
  // Check if there"s an app div, if so initialize the app in that div
  if ($("#app").length > 0) {
    const body = $("body")
    const app = document.getElementById("app")
    const root = createRoot(app)

    const router = new Router()

    root.render(
      <ApolloProvider client={apolloClient}>
        <App
          appsignal={appsignal}
          token={body.data("user-token")}
          site_name={body.data("site-name")}
          formatters={{
            date: body.data("date-format"),
            number: body.data("number-format"),
            timeZone: body.data("time-zone"),
          }}
          platforms={body.data("platforms")}
          enabledLabFeatures={body.data("enabled-lab-features")}
          router={router}
          chartSettings={{
            horizontalSupportLines: body.data("chart-show-horizontal-lines"),
            verticalSupportLines: body.data("chart-show-vertical-lines"),
            colorPalette: body.data("chart-color-palette"),
            lineThickness: body.data("chart-line-thickness"),
            showMarkers: body.data("chart-show-markers"),
          }}
          restClientSettings={{
            endpoint: body.data("rest-endpoint"),
            token: body.data("rest-token"),
          }}
        />
      </ApolloProvider>,
    )
  }
})
