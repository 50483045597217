export default class Metrics {
  client

  constructor(client) {
    this.client = client
  }

  timeseries(params, options = {}) {
    return this.client.post("/api/v2/metrics/timeseries", params, options)
  }

  metricNames(site_id, options = {}) {
    return this.client.get(`/api/v2/metrics/names/${site_id}`, options)
  }

  typeAndTags(site_id, metric_name, options = {}) {
    return this.client.get(`/api/v2/metrics/type_and_tags/${site_id}/${metric_name}`, options)
  }

  list(params) {
    return this.client.post("/api/v2/metrics/list", params)
  }
}
