import { useMutation, useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Form, { useForm } from "#Components/forms/Form"
import Message from "#Components/ui/Message"
import createMutation from "#Graphql/createStatusPageMutation"
import deleteMutation from "#Graphql/deleteStatusPageMutation"
import statusPageFormQuery from "#Graphql/statusPageFormQuery"
import statusPagesQuery from "#Graphql/statusPagesQuery"
import updateMutation from "#Graphql/updateStatusPageMutation"
import { Button } from "#Root/ui"
import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"

import Ident from "../../utils/Ident"
import EnvironmentLabel from "../shared/environment_label"
import ErrorBox from "../shared/error_box"
import ValidationErrors, { scrollValidationErrorsIntoView } from "../shared/validation_errors"

const states = {
  new: {
    submitLabel: "Create new status page",
  },
  edit: {
    submitLabel: "Update status page",
  },
}

const StatusPageForm = ({ organizationSlug, statusPageId, closeOverlay }) => {
  const [mutationError, setMutationError] = useState(null)
  const [customDomain, setCustomDomain] = useState(false)
  const onSuccess = (identMetric) => {
    setMutationError(null)
    closeOverlay()
    Ident.trackAction(identMetric)
  }
  const onError = (message, error) => {
    // eslint-disable-next-line no-console
    setMutationError(error)
    scrollValidationErrorsIntoView()
  }

  // Mutations to create/update/delete the monitor
  const [createStatusPage] = useMutation(createMutation, {
    onCompleted() {
      onSuccess("StatusPageOverlayCreate")
    },
    onError(error) {
      onError("Error creating uptime monitor", error)
    },
    refetchQueries: [
      {
        query: statusPagesQuery,
        variables: { organizationSlug },
      },
    ],
  })
  const [updateStatusPage] = useMutation(updateMutation, {
    onCompleted() {
      onSuccess("StatusPageOverlayUpdate")
    },
    onError(error) {
      onError("Error updating uptime monitor", error)
    },
  })
  const [deleteStatusPage] = useMutation(deleteMutation, {
    onCompleted() {
      onSuccess("StatusPageOverlayDelete")
    },
    onError(error) {
      onError("Error removing uptime monitor", error)
    },
    refetchQueries: [
      {
        query: statusPagesQuery,
        variables: { organizationSlug },
      },
    ],
  })

  // Form handlers
  const stateKey = statusPageId ? "edit" : "new"
  const state = states[stateKey]
  const formControl = useForm({ mode: "onChange" })
  const handleSubmit = (data) => {
    if (data.uptimeMonitorIds === false) data.uptimeMonitorIds = null
    const payload = { organizationSlug, statusPage: data, id: statusPageId }
    if (stateKey === "edit") {
      updateStatusPage({ variables: payload })
    } else {
      if (!customDomain) {
        payload.statusPage.hostname = `${payload.statusPage.hostname}.appsignal-status.com`
      }
      createStatusPage({ variables: payload })
    }
  }
  const handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm("Are you sure you want to delete this status page?")) {
      deleteStatusPage({ variables: { id: statusPageId, organizationSlug } })
    }
  }

  // Get the status page to update and the Uptime Monitors for this org
  const { data, loading, error } = useQuery(statusPageFormQuery, {
    variables: { organizationSlug, id: statusPageId },
  })

  // Render states or form
  if (loading) {
    return <LoadingAnimation message="Loading data" />
  }
  if (error) {
    return <ErrorBox error={error} />
  }
  const hasNoUptimeMonitors =
    data.organization.apps.filter((a) => a.uptimeMonitors.length > 0).length == 0

  const {
    organization: { statusPage },
  } = data

  return (
    <div className="c-box mb-0">
      <h2 className="c-box__header">Status page settings</h2>
      {mutationError && <ValidationErrors className="mt-4" error={mutationError} />}
      <div className="py-4 px-5">
        <Form formControl={formControl} onSubmit={(data, event) => handleSubmit(data, event)}>
          <Form.Field>
            <Form.Label name="title">Status page title</Form.Label>
            <Form.Hint>Required</Form.Hint>
            <Form.TextField
              name="title"
              defaultValue={statusPage ? statusPage.title : ""}
              placeholder="e.g. ACME Corp | Status"
              validation={{ required: true }}
            />
            <Form.Description>
              This is used as the HTML title tag. Should probably include your company name.
            </Form.Description>
          </Form.Field>

          <Form.Field>
            <Form.Label name="description">Meta description</Form.Label>
            <Form.Hint>Required</Form.Hint>
            <Form.TextArea
              defaultValue={
                statusPage
                  ? statusPage.description
                  : `The status page for ${data.organization.name}.`
              }
              name="description"
              placeholder="Meta description"
              autosize={true}
              minRows={4}
              validation={{ required: true }}
            />
            <Form.Description>
              This is used as the HTML description tag for SEO purposes.
            </Form.Description>
          </Form.Field>

          <Form.Field>
            <Form.Label name="threshold">Downtime threshold</Form.Label>
            <Form.Hint>Required</Form.Hint>
            <Form.TextField
              defaultValue={statusPage ? statusPage.threshold || 5 : 5}
              name="threshold"
              placeholder="Number in minutes"
              validation={{ required: true, valueAsNumber: true }}
              min={1}
              type="number"
            />
            <Form.Description>
              Any downtime below this threshold will not be shown as downtime on your status page.
            </Form.Description>
          </Form.Field>

          <hr className="ignore-old-css -mx-5 my-6" />

          {stateKey != "edit" && (
            <>
              <Form.Field>
                <Form.Label name="hostname">
                  <span className="flex">
                    <div className="c-radio items-baseline mr-4">
                      <input
                        className="c-radio__input mr-2"
                        type="radio"
                        id="check_subdomain"
                        name="radio"
                        value="false"
                        checked={!customDomain}
                        onChange={() => {
                          formControl.setValue("hostname", "")
                          setCustomDomain(false)
                        }}
                      />
                      <label className="c-radio__label cursor-pointer" htmlFor="check_subdomain">
                        Subdomain
                      </label>
                    </div>
                    <div className="c-radio items-baseline">
                      <input
                        className="c-radio__input mr-2"
                        type="radio"
                        id="check_custom"
                        name="check_custom"
                        value="true"
                        checked={customDomain}
                        onChange={() => {
                          formControl.setValue("hostname", "")
                          setCustomDomain(true)
                        }}
                      />
                      <label className="c-radio__label cursor-pointer" htmlFor="check_custom">
                        Custom domain
                      </label>
                    </div>
                  </span>
                </Form.Label>
                <Form.Hint>Required</Form.Hint>
                <Form.TextField
                  name="hostname"
                  placeholder={customDomain ? "your.own.domain" : "subdomain"}
                  validation={{ required: true }}
                  suffix={customDomain ? null : ".appsignal-status.com"}
                />
                <Form.Description>
                  {customDomain ? (
                    <div className="c-message c-message--yellow mt-4 text-xs">
                      <h3 className="c-message__heading">Custom domain instructions</h3>
                      <p className="mt-2">
                        Create a <code className="c-inline-code">CNAME</code> record pointing to{" "}
                        <code className="c-inline-code">cname.appsignal-status.com</code> and ensure
                        your DNS has propagated this change before saving the form.
                      </p>
                      <p className="mt-2">
                        For more information on setting up a CNAME record,{" "}
                        <a
                          href="https://docs.appsignal.com/uptime-monitoring/public-status-page#setup"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="c-link"
                        >
                          our documentation
                        </a>
                        .
                      </p>
                    </div>
                  ) : (
                    <p>
                      The subdomain under which your status page will be available. For now, you won
                      {"'"}t be able to change this after saving this form. It can take up to 10
                      minutes before the page is available.
                    </p>
                  )}
                </Form.Description>
              </Form.Field>
              <hr className="ignore-old-css -mx-5 my-6" />
            </>
          )}

          <Form.Field>
            <Form.Label name="uptimeMonitors">Uptime monitors</Form.Label>
            <div className="c-form__element mt-2">
              {hasNoUptimeMonitors ? (
                <Message color="yellow" title="Your organization doesn't have any uptime monitors.">
                  <p>
                    <a
                      href="https://docs.appsignal.com/uptime-monitoring/setup.html#setup"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Create an uptime monitor
                    </a>{" "}
                    to add it to your status page.
                  </p>
                </Message>
              ) : (
                <div className="c-box overflow-hidden">
                  <table className="c-table">
                    <colgroup>
                      <col className="w-12" />
                      <col className="" />
                    </colgroup>
                    <tbody>
                      {data.organization.apps
                        .filter((a) => a.uptimeMonitors.length > 0)
                        .map((app) => (
                          <React.Fragment key={app.id}>
                            <tr>
                              <th className="c-table__th space-x-2" colSpan="2">
                                <span>{app.name}</span>
                                <EnvironmentLabel env={app.environment} />
                              </th>
                            </tr>
                            {app.uptimeMonitors.map((um) => (
                              <tr key={um.id}>
                                <td className="c-table__td w-12 px-2 py-0 text-center">
                                  <input
                                    type="checkbox"
                                    name="uptimeMonitorIds"
                                    value={um.id}
                                    defaultChecked={
                                      statusPage
                                        ? statusPage.uptimeMonitorIds.includes(um.id)
                                        : false
                                    }
                                    {...formControl.register("uptimeMonitorIds", {})}
                                  />
                                </td>
                                <td className="c-table__td pl-0">
                                  <p className="whitespace-nowrap">{um.name}</p>
                                  <p className="text-gray-700 truncate flex-shrink">{um.url}</p>
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Form.Field>

          <div className="flex -mx-5 px-5 pt-4 border-t">
            <div className="space-x-3">
              <Form.SubmitButton>{state.submitLabel}</Form.SubmitButton>
              <Button onClick={closeOverlay} color="white">
                Cancel
              </Button>
            </div>
            {stateKey === "edit" && (
              <Button onClick={handleDelete} color="red" className="ml-auto">
                Remove status page
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

StatusPageForm.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
  statusPageId: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
}

export default StatusPageForm
