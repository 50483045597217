import BarChart from "#Components/shared/bar_chart"
import Duration from "#Components/shared/duration"

const DurationCell = ({ columnId, value, maxValues }) => {
  return (
    <div className="space-y-1">
      <Duration val={value} />
      <BarChart max={maxValues[columnId]} val={value} />
    </div>
  )
}

DurationCell.propTypes = {
  columnId: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  maxValues: PropTypes.object.isRequired,
}

export default DurationCell
