import BarChart from "#Components/shared/bar_chart"
import FormattedNumber from "#Root/formatters/FormattedNumber"

export const NumberCell = ({
  cellData,
  column: { dataKey },
  container: {
    props: { maxValues },
  },
}) => (
  <div className="c-virtual-table__row-cell-content">
    <div className="space-y-1">
      <FormattedNumber value={cellData} />
      <BarChart max={maxValues[dataKey]} val={cellData} />
    </div>
  </div>
)

NumberCell.propTypes = {
  cellData: PropTypes.number.isRequired,
  container: PropTypes.shape({
    props: PropTypes.shape({
      maxValues: PropTypes.object.isRequired,
    }),
  }),
  column: PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
  }),
}

export default NumberCell
