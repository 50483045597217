import PropTypes from "prop-types"
import React from "react"

import TruncatedText from "#Root/components/shared/TruncatedText"
import { Box } from "#Root/ui"
import Chart from "#Root/ui/ASChart"
import { MARKERS_HEIGHT } from "#Root/ui/ASChart/constants"

const DISPLAY_TYPES = {
  LINE: "line",
  AREA: "area",
  AREA_RELATIVE: "area-relative",
  BAR: "bar",
}

function titleWithFallback(title) {
  if ((title || "").trim() === "") {
    return <em>Untitled graph</em>
  } else {
    return title
  }
}

function warnings(visual) {
  if (visual.metrics && visual.metrics.length === 0) {
    return {
      icon: "fa-chart-area",
      message: (
        <>
          <span className="font-bold mb-2">New graph</span>
          <span>Start building a new graph by adding a metric in the sidebar on the left.</span>
        </>
      ),
    }
  }

  return {
    icon: "fa-chart-area",
    message: (
      <>
        <span className="font-bold mb-2">No data found</span>
        <span>No data was found to render the graph.</span>
        <span>Please check you graph configuration for errors and warnings.</span>
      </>
    ),
  }
}

const VisualBuilderTimeseriesPreview = ({
  appId,
  visualPreview: visual,
  showLineLegend,
  timeframe,
}) => {
  const {
    display: type,
    description,
    lineLabel,
    format: valueFormat,
    formatInput: valueInput,
    drawNullAsZero,
    minYAxis,
    metrics,
  } = visual
  const title = titleWithFallback(visual.title)
  const noDataMessage = warnings(visual)
  const renderer = DISPLAY_TYPES[type ? type : "LINE"]

  const queries = metrics.flatMap((metric) => {
    return metric.fields.map(({ field }) => {
      return {
        name: metric.name,
        field,
        tags: metric.tags || [],
        drawNullAsZero,
      }
    })
  })

  return (
    <>
      <Chart.Rest appId={appId} queries={queries} timeframe={timeframe}>
        <Chart.Container renderer={renderer} label={lineLabel || "%name%"} minYAxis={minYAxis}>
          <Box>
            <Box.Header>
              <div className="grid grid-flow-col justify-between">
                <TruncatedText
                  text={
                    <>
                      <span className="text-gray-800 font-semibold">{title} Preview</span>
                      {description && <span className="font-normal ml-2">{description}</span>}
                    </>
                  }
                  textClassName="w-full text-gray-600"
                  tooltip={
                    <>
                      <p className="font-semibold mb-1">{title}</p>
                      <p>{description}</p>
                    </>
                  }
                />
                <div className="self-center">
                  <Chart.ExportButton
                    title={title}
                    valueFormat={valueFormat || "number"}
                    valueInput={valueInput}
                  />
                </div>
              </div>
            </Box.Header>
            <div className="pt-5 px-5">
              <Chart.Graph
                valueFormat={valueFormat}
                valueInput={valueInput}
                previewMode={showLineLegend}
                emptyRenderer={(height) => (
                  <div>
                    <div
                      className="loader centered no-markers no-data-message text-gray-600"
                      style={height}
                    >
                      <span className="rounded-full bg-blue-100 mb-4 w-12 h-12 flex justify-center items-center text-xl">
                        <i className={`fa fa-fw ${noDataMessage.icon}`} />
                      </span>
                      {noDataMessage.message}
                    </div>
                    {/* offset for markers */}
                    <div style={{ height: MARKERS_HEIGHT }} className="mt-2" />
                  </div>
                )}
              >
                <Chart.YAxis />
                <Chart.XAxis />
                <Chart.Hover>
                  <Chart.Hover.MarkerButton />
                  <Chart.Hover.TimeDetectiveButton />
                </Chart.Hover>
                <Chart.Hover.PreviewMode enabled={showLineLegend} />
                <Chart.HighlightLine enableLock={true} />
                <Chart.HighlightDot />
                <Chart.Markers />
              </Chart.Graph>
            </div>
          </Box>
        </Chart.Container>
      </Chart.Rest>
    </>
  )
}

VisualBuilderTimeseriesPreview.propTypes = {
  appId: PropTypes.string.isRequired,
  visualPreview: PropTypes.object.isRequired,
  showLineLegend: PropTypes.bool.isRequired,
  timeframe: PropTypes.string.isRequired,
}

export default VisualBuilderTimeseriesPreview
