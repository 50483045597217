import PropTypes from "prop-types"
import React from "react"

import { wildcardSearch } from "#Root/utils/string"

import { filterArrayWith } from "../../utils/Array"
import PopOver from "../shared/PopOver"

export class TextfieldWithAutoComplete extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    message: PropTypes.node,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    containerClasses: PropTypes.string,
    popOverClasses: PropTypes.string,
    popOverHeader: PropTypes.string,
    wildcardSupport: PropTypes.bool,
    hasError: PropTypes.bool,
    hasWarning: PropTypes.bool,
    autoComplete: PropTypes.string,
  }

  static defaultProps = {
    id: "",
    containerClasses: "",
    popOverClasses: "",
    wildcardSupport: false,
    hasWarning: false,
    hasError: false,
  }

  state = {
    focus: false,
    value: this.props.value,
    activeIndex: 0,
  }

  popoverRef = React.createRef()
  activeOptionRef = React.createRef()

  handleFocus() {
    this.setState({ focus: true })
  }

  handleBlur(event) {
    // check if the event that triggered onBlur is inside the popoverRef
    // like using the scrollbar. If so, ignore it
    if (this.popoverRef.current && this.popoverRef.current.contains(event?.relatedTarget)) {
      return
    }

    this.selectCurrentValue()
  }

  handleKeyDown(e) {
    const { activeIndex } = this.state
    switch (e.keyCode) {
      case 38: {
        // Up arrow
        let newIndex = activeIndex - 1
        if (newIndex <= 0) {
          newIndex = 0
        }
        this.setState({ activeIndex: newIndex }, (_) => {
          this.scrollToActiveOption()
        })
        break
      }
      case 40: {
        // Down arrow
        let newIndex = activeIndex + 1
        const options = this.filteredOptions()
        const maxIndex = options.length - 1
        if (newIndex >= maxIndex) {
          newIndex = maxIndex
        }
        this.setState({ activeIndex: newIndex }, (_) => {
          this.scrollToActiveOption()
        })
        break
      }
      case 13: {
        // Enter button
        e.preventDefault()
        e.stopPropagation()
        this.selectCurrentOption()
        break
      }
      case 27: {
        // Escape button
        e.preventDefault()
        e.stopPropagation()
        this.selectCurrentValue()
        break
      }
    }
  }

  scrollToActiveOption() {
    const { popoverRef, activeOptionRef } = this
    const popover = popoverRef.current
    const option = activeOptionRef.current
    if (!popover || !option) {
      return
    }

    const popoverHeight = popover.offsetHeight
    const topY = popover.scrollTop
    const bottomY = topY + popoverHeight
    const optionTopY = option.offsetTop
    const optionBottomY = optionTopY + option.offsetHeight

    if (bottomY < optionBottomY) {
      // Scroll down, bottom of option - popoverHeight = scroll to top of
      // popover while option is at the bottom
      popover.scrollTo(0, optionBottomY - popoverHeight)
    } else if (topY > optionTopY) {
      // Scroll up, option is at top of popover
      popover.scrollTo(0, optionTopY)
    }
  }

  handleClose() {
    this.selectCurrentValue()
  }

  selectCurrentValue() {
    this.setState({ focus: false, activeIndex: 0 }, (_) => {
      const { value } = this.state
      this.props.onSelect(value)
    })
  }

  selectCurrentOption() {
    const { activeIndex, focus } = this.state
    if (!focus) {
      return
    }

    const options = this.filteredOptions()
    const option = options[activeIndex]
    if (option) {
      const value = option.value
      this.setState({ focus: false, activeIndex: 0, value }, (_) => {
        this.props.onSelect(value)
      })
    } else {
      this.selectCurrentValue()
    }
  }

  handleChange(e) {
    this.setState({ focus: true, value: e.target.value })
  }

  handleClick(option, event) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ focus: false, activeIndex: 0, value: option }, (_) => {
      this.props.onSelect(option)
    })
  }

  optionClassNames(index) {
    const { activeIndex } = this.state
    const classes = ["px-3 py-1 block cursor-pointer no-underline"]
    if (activeIndex === index) {
      classes.push("bg-blue-100")
    }
    return classes.join(" ")
  }

  hasWildcard() {
    const { wildcardSupport } = this.props
    if (!wildcardSupport) {
      return false
    }

    const { value } = this.state
    return value.includes("*")
  }

  filteredOptions() {
    const { options } = this.props
    const { value } = this.state

    const hasWildcard = this.hasWildcard()
    const list = []
    const filteredOptions = options
      .filter((option) => wildcardSearch(option, value, hasWildcard))
      .map((option) => ({
        label: option,
        value: option,
      }))

    if (hasWildcard) {
      list.push({
        label: (
          <>
            {value}
            <br />
            <em>Wildcard matches {filteredOptions.length} items</em>
          </>
        ),
        value,
      })
    }
    return list.concat(filteredOptions)
  }

  matchedOptions() {
    const { options } = this.props
    const { value } = this.state
    const hasWildcard = this.hasWildcard()
    const filteringMatcher = hasWildcard ? "wildcardEqual" : "equal"

    return filterArrayWith(value, options, filteringMatcher)
  }

  renderOptions() {
    const { message } = this.props
    if (message) {
      return (
        <div className={this.optionClassNames(-1)}>
          <em>{message}</em>
        </div>
      )
    }
    const options = this.filteredOptions()
    if (options.length === 0) {
      return (
        <div className={this.optionClassNames(-1)}>
          <em>No items found</em>
        </div>
      )
    }

    const { activeIndex } = this.state
    return options.map(({ label, value }, index) => {
      const opts = {}
      if (activeIndex === index) {
        opts.ref = this.activeOptionRef
      }
      return (
        <a
          key={`option-${index}`}
          onMouseDown={this.handleClick.bind(this, value)}
          className={this.optionClassNames(index)}
          {...opts}
        >
          {label}
        </a>
      )
    })
  }

  renderWarningAndErrors() {
    const { hasError, hasWarning } = this.props

    if (hasError) {
      return <i className="fa fa-exclamation-circle c-textfield__icon-right text-red" />
    }
    if (hasWarning) {
      return <i className="fa fa-exclamation-triangle c-textfield__icon-right text-orange" />
    }
  }

  renderHeader() {
    const { popOverHeader } = this.props
    if (!popOverHeader) {
      return
    }

    return (
      <div className="uppercase tracking-wide font-semibold text-gray-600 py-1 px-3">
        {popOverHeader}
      </div>
    )
  }

  containerClasses() {
    const { hasError, hasWarning } = this.props

    if (hasError) {
      return "c-textfield--error"
    }
    if (hasWarning) {
      return "c-textfield--warning"
    }
    return ""
  }

  render() {
    const { id, name, placeholder, containerClasses, popOverClasses, autoComplete } = this.props
    const { focus, value } = this.state
    return (
      <div className="group relative">
        <div className={`c-textfield ${containerClasses} ${this.containerClasses()}`}>
          <input
            type="text"
            id={id}
            name={name}
            className="c-textfield__input"
            value={value || ""}
            placeholder={placeholder}
            autoComplete={autoComplete || `appsignal-complete-${name}`}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            onKeyDown={this.handleKeyDown.bind(this)}
            onChange={this.handleChange.bind(this)}
          />
          {this.renderWarningAndErrors()}
        </div>
        {focus && (
          <PopOver
            innerRef={this.popoverRef}
            className={`mt-2 py-2 ${popOverClasses} break-all`}
            height="max-h-52"
            onClose={this.handleClose.bind(this)}
            withEscapeListener={false}
          >
            {this.renderHeader()}
            {this.renderOptions()}
          </PopOver>
        )}
      </div>
    )
  }
}

export default TextfieldWithAutoComplete
