import { bisector } from "d3-array"
import { drag } from "d3-drag"
import { select } from "d3-selection"
import React from "react"

import cn from "#Root/utils/cn"

import { BAR } from "../constants"
import { useChartDataContext } from "../contexts/DataContext"
import { useDraggingContext } from "../contexts/DraggingContext" // Import the DraggingContext
import { useChartGraphContext } from "../contexts/GraphContext"
import { useChartHoverStateContext } from "../contexts/HoverStateContext"
import { captureMouseLeave } from "../utils/captureMouseLeave"
import Area from "./visuals/Area"
import Bar from "./visuals/Bar"
import Line from "./visuals/Line"
import Sparkline from "./visuals/Sparkline"

const Canvas = () => {
  const { width, canvasWidth, canvasHeight, xScale, canvasRef, zoomEnabled, domain, onClick } =
    useChartGraphContext()
  const { isScaleOverridden, restoreScales, setScaleCoordinates } = useChartGraphContext()
  const { dragging, setDragging, dragCoords, setDragCoords } = useDraggingContext()
  const { setHoverState, hoverRef, lockRef, unlock, lockState, updateHoverPosition } =
    useChartHoverStateContext()
  const { timeseries, visibleSeries, renderer } = useChartDataContext()
  const mainRef = React.useRef(null)

  const xOffset = width - canvasWidth
  xScale.domain(domain.x)

  const locked = lockState.locked
  React.useLayoutEffect(() => {
    mainRef.current = document.querySelector("#main")
    if (!mainRef.current) {
      return () => {}
    }

    const handleScroll = () => {
      if (locked) {
        // Update the vertical position of the hover state when locked and the viewport is scrolled
        updateHoverPosition(mainRef.current.scrollTop)
      } else {
        // Clear the hover state when the viewport is scrolled as we can mouseover a different item
        setHoverState({})
      }
    }
    mainRef.current.addEventListener("scroll", handleScroll, { passive: true })

    return () => mainRef.current.removeEventListener("scroll", handleScroll)
  }, [setHoverState, locked, updateHoverPosition])

  React.useEffect(() => {
    if (canvasRef.current && canvasWidth > 0 && zoomEnabled) {
      const { start, end } = dragCoords

      if (start && end && !dragging) {
        // Calculate the drag distance
        const dragDistance = Math.abs(end.x - start.x)
        // Get the width of a single data point slot
        const dataPointWidth = canvasWidth / timeseries.series[0].data.length

        // Exit if we selected something smaller, like just clicking
        if (dragDistance <= dataPointWidth) {
          return // this should be undefined, as `null` can break `useEffect`.
        }

        // Get the coordinates of the rectangle
        const startX = Math.min(start.x, end.x)
        const endX = Math.max(start.x, end.x)
        const startY = Math.min(start.y, end.y)
        const endY = Math.max(start.y, end.y)

        // Reset the drag state
        setDragCoords({ start: null, end: null })

        // Remove any locks, as we apply a new area that might not contain the locked item
        unlock()

        // Reset tooltip, to avoid any flicker
        setHoverState({})

        // And ask the graph to zoom in on the selected area
        setScaleCoordinates({
          x: [startX, endX],
          y: [startY, endY],
        })
      }
    }
  }, [
    canvasRef,
    dragging,
    dragCoords,
    canvasWidth,
    setScaleCoordinates,
    unlock,
    zoomEnabled,
    setDragCoords,
    setHoverState,
    timeseries,
  ])

  React.useEffect(() => {
    if (!canvasRef.current || canvasWidth <= 0 || !zoomEnabled) {
      return
    }

    const svg = select(canvasRef.current)
    const bisectDate = bisector((d) => d.x).left

    const snapToDataPoint = (x) => {
      const xDate = xScale.invert(x)
      const index = bisectDate(timeseries.series[0].data, xDate, 1)
      const d0 = timeseries.series[0].data[index - 1]
      const d1 = timeseries.series[0].data[index]
      return xScale(d0 && d1 ? (xDate - d0.x > d1.x - xDate ? d1.x : d0.x) : d0 ? d0.x : d1.x)
    }

    svg.call(
      drag()
        .on("start", (event) => {
          setDragging(true)
          const x = snapToDataPoint(event.x - xOffset)

          setDragCoords({ start: { x, y: event.y }, end: null })
        })
        .on("drag", (event) => {
          if (!canvasRef.current) {
            return
          }

          const elPos = canvasRef.current.getBoundingClientRect()

          // Keep within bounds
          let x = snapToDataPoint(event.x - xOffset)
          if (x < 0) x = 0
          else if (x > elPos.width) x = elPos.width

          // Keep within bounds
          let y = event.y
          if (y < 0) y = 0
          else if (y > elPos.height) y = elPos.height

          setDragCoords((coords) => ({ ...coords, end: { x, y } }))
        })
        .on("end", (event) => {
          if (!canvasRef.current) {
            setDragging(false)
            return
          }

          setDragging(false)
          const x = snapToDataPoint(event.x - xOffset)
          setDragCoords((coords) => ({ ...coords, end: { x, y: event.y } }))
        }),
    )

    return () => {
      svg.on(".drag", null).on(".start", null).on(".end", null)
    }
  }, [canvasRef, canvasWidth, setDragCoords, setDragging, timeseries, xOffset, xScale, zoomEnabled])

  if (canvasWidth < 0) {
    return null
  }

  const getInvertDateFromMouse = (event) => {
    if (!canvasRef.current) {
      return null
    }

    const elPos = canvasRef.current.getBoundingClientRect()
    const mouseX = event.clientX - elPos.left

    const invert = xScale.invert(mouseX)

    // Bar chart has a very wide range for hover, so if we have a match it's correct
    // We're using bisectRight
    if (renderer === BAR) {
      const bisectDate = bisector((d) => d.x).right
      const dataIndex = bisectDate(timeseries.series[0].data, invert)

      const adjustedDataIndex = Math.max(0, dataIndex - 1)
      const invertDate = timeseries.series[0].data[adjustedDataIndex].x

      return { invertDate, dataIndex: adjustedDataIndex }
    }

    // For anything else we need to check if we are within a certain range.
    // We don't want a single pixel movement to the left, to highlight a distant point.
    // We're using bisectCenter
    const bisectDate = bisector((d) => d.x).center
    const dataIndex = bisectDate(timeseries.series[0].data, invert, 1)

    // Handle the corner edge case
    if (mouseX <= 0) {
      return {
        invertDate: timeseries.series[0].data[0].x,
        dataIndex: 0,
      }
    }

    if (dataIndex >= 0 && dataIndex < timeseries.series[0].data.length) {
      const dataPoint = timeseries.series[0].data[dataIndex]
      const dataPointX = xScale(dataPoint.x)

      const isWithinAcceptableRange = Math.abs(dataPointX - mouseX) <= 10

      return {
        invertDate: isWithinAcceptableRange ? dataPoint.x : null,
        dataIndex,
      }
    }

    return null
  }

  const handleMouseMove = (event) => {
    const selection = getInvertDateFromMouse(event)

    // The ref isn't set yet, so we can't update the hover state
    if (!selection) {
      return null
    }

    const { invertDate, dataIndex } = selection

    const main = document.querySelector("#main")
    const currentScrollOffset = main ? main.scrollTop : 0

    if (invertDate !== null) {
      setHoverState({
        mouseX: event.clientX - canvasRef.current.getBoundingClientRect().left,
        mouseY: event.clientY - canvasRef.current.getBoundingClientRect().top,
        initialScrollOffset: currentScrollOffset,
        invertDate,
        canvasWidth,
        dataIndex: dataIndex,
      })
    }
  }

  const handleClick = (event) => {
    if (!dragging && timeseries.series[0].data && onClick) {
      const { invertDate } = getInvertDateFromMouse(event)

      if (invertDate !== null) {
        onClick(invertDate)
      }
    }
  }

  return (
    <>
      <svg
        ref={canvasRef}
        style={{
          position: "absolute",
          top: "0px",
          left: `${width - canvasWidth}px`,
          width: `${canvasWidth}px`,
          height: `${canvasHeight}px`,
        }}
        className={cn({
          "cursor-pointer": onClick && !dragging,
          "cursor-grabbing": dragging,
        })}
        onMouseLeave={(event) =>
          captureMouseLeave({ event, hoverRef, lockRef, canvasRef, onExit: setHoverState })
        }
        onMouseMove={(event) => handleMouseMove(event)}
        onClick={handleClick}
      >
        {/* Main Canvas Area */}
        <g>
          {visibleSeries.map((serie) => {
            switch (renderer) {
              case "bar":
                return <Bar key={serie.id} {...serie} />
              case "area":
              case "area-relative":
                return <Area key={serie.id} {...serie} />
              case "sparkline":
                return <Sparkline key={serie.id} {...serie} />
              default:
                return <Line key={serie.id} {...serie} />
            }
          })}
          {dragging && dragCoords.start && dragCoords.end && (
            <rect
              style={{ zIndex: 100 }}
              x={Math.min(dragCoords.start.x, dragCoords.end.x)}
              y={Math.min(dragCoords.start.y, dragCoords.end.y)}
              width={Math.abs(dragCoords.start.x - dragCoords.end.x)}
              height={Math.abs(dragCoords.start.y - dragCoords.end.y)}
              fill="#00000010"
              stroke="#00000090"
              strokeWidth={1}
              strokeDasharray={4}
            />
          )}
        </g>
      </svg>
      {isScaleOverridden && (
        <div className="absolute right-0 top-0 z-[39]">
          <button
            className="c-button c-button--white c-button--xs -my-2 py-2 text-gray-700 shadow-none hover:text-gray-800 border-gray-200 hover:shadow-sm cursor-pointer"
            onClick={restoreScales}
          >
            <i className="c-button__icon fas fa-undo"></i>
          </button>
        </div>
      )}
    </>
  )
}

export default Canvas
