import {
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 3,
    },
  },
})

export const ReactQueryProvider = ({ children }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

ReactQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useReactQueryClient = () => useQueryClient()

export { useInfiniteQuery, useMutation, useQuery }

const testQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
})

export const MockReactQueryProvider = ({ children }) => {
  return <QueryClientProvider client={testQueryClient}>{children}</QueryClientProvider>
}

MockReactQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
