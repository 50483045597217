import { useQuery } from "@tanstack/react-query"

import { useRestClientContext } from "#Root/contexts/RestClientContext"

export const useMetricTypeAndTags = ({ enabled, metricName, appId }) => {
  const restApiClient = useRestClientContext()

  const {
    data,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["typeAndTags", { appId, metricName }],
    queryFn: () => restApiClient.metrics.typeAndTags(appId, metricName),
    enabled,
  })

  /* eslint-disable */
  const metadata = data
    ? {
        type: data.metric_type,
        tagCombinations: data.available_tags,
        tags: Array.from(new Set(data.available_tags.flat())),
      }
    : null

  return {
    metadata,
    loading,
    error,
  }
}
