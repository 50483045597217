import { IS_MAC_OS } from "#Root/constants/KeyboardShortcuts"

const KeyboardShortcut = ({ shortcut, className }) => {
  const classNames = ["c-pill border", className].filter((i) => i).join(" ")
  const parts = shortcut
    .split("+")
    .map((part) => {
      switch (part) {
        case "meta":
          return IS_MAC_OS ? "⌘" : "⊞"
        case "ctrl":
          return "⌃"
        case "alt":
          return "⌥"
        case "option":
          return "⌥"
        case "shift":
          return "⇧"
        case "enter":
          return "↵"
        default:
          return part
      }
    })
    .join("+")

  return <code className={classNames}>{parts}</code>
}

KeyboardShortcut.propTypes = {
  shortcut: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default KeyboardShortcut
